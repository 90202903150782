<template>
  <div style="height: 100vh">
    <div
      class="h-100 align-items-center justify-content-center d-none d-md-flex"
    >
      <b-card style="" class="px-1 m-0">
        <div class="mt-1">
          <b-link :to="{ name: 'Login' }">
            <img :src="this.appLogoImage" img-fluid width="200" height="75"
          /></b-link>
        </div>

        <b-card-title
          title-tag="h2"
          class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
        >
          Check Your Email.
        </b-card-title>
        <b-card-text class="text-colorGray" style="font-size: 0.8rem">
          Please check the email address Uxbuilder@gmail.com for instructions
          <br />
          to reset your password.
        </b-card-text>

        <validation-observer ref="verificationCodeFormValidation">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <b-form-group label-for="verification-code">
              <validation-provider
                #default="{ errors }"
                name="Verification Code"
                rules="required"
              >
                <span class="d-flex">
                  <div
                    v-for="(digit, index) in codeLength"
                    :key="index"
                    class="digit-block"
                  >
                    <input
                      v-model="verificationCode[index]"
                      type="text"
                      maxlength="1"
                      @input="focusNextInput(index, $event)"
                      ref="inputRefs"
                      class="form-control"
                    />
                  </div>
                </span>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              variant="colorBlue"
              class="mt-2"
              block
              :to="{ name: 'NewPassword' }"
              @click="validateForm"
            >
              Continue
            </b-button>
          </b-form>
        </validation-observer>
        <div class="pt-3 text-center font-weight-bold text-colorBlack">
          <p>
            Haven’t received code?
            <b-link class="font-weight-bolder text-colorBlue">
              Send Again.</b-link
            >
          </p>
        </div>
      </b-card>
    </div>

    <div class="d-block d-md-none h-100">
      <div style="" class="m-0 h-100">
        <b-row style="height: 10%">
          <b-col md="12" style="border-bottom: 2px solid lightgrey">
            <b-row>
              <b-col sm="12" class="p-0">
                <b-navbar toggleable="lg" class="px-2">
                  <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                  <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                      <b-nav-item href="#">Link</b-nav-item>
                    </b-navbar-nav>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                      <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item href="#">EN</b-dropdown-item>
                        <b-dropdown-item href="#">ES</b-dropdown-item>
                        <b-dropdown-item href="#">RU</b-dropdown-item>
                        <b-dropdown-item href="#">FA</b-dropdown-item>
                      </b-nav-item-dropdown>

                      <b-nav-item-dropdown right>
                        <template #button-content>
                          <em>User</em>
                        </template>
                        <b-dropdown-item href="#">Profile</b-dropdown-item>
                        <b-dropdown-item href="#">Sign Out</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                  </b-collapse>
                  <b-navbar-brand href="#">
                    <img
                      :src="this.appLogoImage"
                      img-fluid
                      width="100"
                      height="75"
                  /></b-navbar-brand>
                </b-navbar>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="px-2 py-auto" style="height: 90%">
          <div class="mt-5 pt-2">
            <b-link :to="{ name: 'Login' }">
              <img :src="this.appLogoImage" img-fluid width="200" height="75"
            /></b-link>
          </div>

          <b-card-title
            title-tag="h2"
            class="font-weight-bolder text-colorBlack mb-50 mt-2 pr-5 mr-5"
          >
            Check Your Email.
          </b-card-title>
          <b-card-text class="text-colorGray" style="font-size: 0.8rem">
            Please check the email address Uxbuilder@gmail.com for instructions
            <br />
            to reset your password.
          </b-card-text>

          <validation-observer ref="verificationCodeFormValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group label-for="verification-code">
                <validation-provider
                  #default="{ errors }"
                  name="Verification Code"
                  rules="required"
                >
                  <span class="d-flex">
                    <div
                      v-for="(digit, index) in codeLength"
                      :key="index"
                      class="digit-block"
                    >
                      <input
                        v-model="verificationCode[index]"
                        type="text"
                        maxlength="1"
                        @input="focusNextInput(index, $event)"
                        ref="inputRefs"
                        class="form-control"
                      />
                    </div>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="colorBlue"
                class="mt-2"
                block
                :to="{ name: 'NewPassword' }"
                @click="validateForm"
              >
                Continue
              </b-button>
            </b-form>
          </validation-observer>
          <div class="pt-3 text-center font-weight-bold text-colorBlack">
            <p>
              Haven’t received code?
              <b-link class="font-weight-bolder text-colorBlue">
                Send Again.</b-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [util],
  data() {
    return {
      appName: "",
      appFullName: "",
      appFullName: "",
      codeLength: 6,
      verificationCode: new Array(6).fill(""),
      required,
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ login: "appData/login" }),
    async validateForm() {
      const success =
        await this.$refs.verificationCodeFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    focusNextInput(index, event) {
      const nextIndex = index + 1;
      if (nextIndex < this.codeLength && event.target.value !== "") {
        this.$refs[`input${nextIndex}`][0].focus();
      }
    },
    async submit() {},
  },
  computed: {
    ...mapGetters({ hasRole: "appData/hasRole" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.digit-container {
  display: flex;
}

.digit-block {
  flex: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.digit-block input {
  width: 100%;
  max-width: 30px; /* adjust as per your design */
  height: 30px; /* adjust as per your design */
  border: 1px solid #ccc; /* adjust as per your design */
  margin: 5px; /* adjust as per your design */
  text-align: center; /* center text within input */
}
</style>
